.transparent {
  color: transparent;
}
:root {
  --toastify-color-dark: #27272a;
  --toastify-color-success: #1dea99;
  --toastify-color-warning: #fcb61d;
  --toastify-color-info: #e4e4e7;
  --toastify-color-error: #fcb61d;
  --toastify-text-color-dark: #e4e4e7;
  --toastify-font-family: Inter, sans-serif;
}

.Toastify__toast {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
  background-color: transparent !important;
}

.ace_text-input {
  position: absolute !important;
}

.primary {
  color: #1dea99;
}

*::selection {
  background-color: #1dea99;
  color: #000;
}

.no-scroll {
  overflow: hidden;
}
