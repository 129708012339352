@import url('https://fonts.cdnfonts.com/css/inter');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

/* Basic */

@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
  overflow-x: clip;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #09090b;
}

body.is-preload *,
body.is-preload *:before,
body.is-preload *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

/* Type */

body,
input,
select,
textarea {
  color: #a1a1aa;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}

a {
  text-decoration-line: underline;
  color: #1dea99;
}

a:hover {
  border-bottom-color: transparent;
  color: #ffffff;
}

/* Fonts Sizes */

h1 {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
}

h2 {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
}

h3 {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

h4 {
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 36px;
}

h5 {
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
}

strong,
b {
  color: #ffffff;
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

p,
a,
li {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.small {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.extra-small {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.uppercase-label {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

code {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

/* Spacing */

h1 {
  margin-top: 85px;
  padding-bottom: 3px;
}

h2 {
  margin-top: 83px;
  padding-bottom: 1px;
}

h3 {
  margin-top: 52px;
  padding-bottom: 4px;
}

h4 {
  margin-top: 40px;
  padding-bottom: 4px;
}

h5 {
  margin-top: 34px;
  padding-bottom: 6px;
}

p {
  margin-top: 23px;
  padding-bottom: 5px;
}

blockquote {
  margin-top: 23px;
}

img,
video,
pre,
table,
select,
textarea,
input {
  margin-top: 32px;
  padding-bottom: 12px;
}

ol,
ul {
  padding-left: 32px;
}

:not(li) > ol,
:not(li) > ul {
  margin-top: 23px;
  padding-bottom: 5px;
}
li > ol,
li > ul {
  margin-top: 14px;
}

li + li {
  margin-top: 14px;
}

ul > li {
  list-style-type: none;
  position: relative;
}

ul > li:before {
  content: '-';
  position: absolute;
  left: -24px;
}

blockquote {
  border-left: solid 4px rgba(255, 255, 255, 0.15);
  font-style: italic;
  padding-left: 14px;
}

pre {
  -webkit-overflow-scrolling: touch;
}

code {
  color: #ffffff;
  background: transparent;
  border: 0;
  padding: 0px 5px 0px;
  box-shadow: 0px 0px 0px 1px #4f5155 inset;
}

pre {
  border-radius: 8px;
}

code {
  border-radius: 4px;
}

pre > code {
  padding: 10px;
  white-space: pre;
  box-shadow: none;
  font-family: Roboto Mono;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

pre code {
  display: block;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.15);
  margin-top: 23px;
  padding-bottom: 5px;
}

input[type='submit'],
input[type='reset'],
input[type='button'],
.button,
button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;

  padding: 15px 19px;
  gap: 4px;

  color: #1dea99;
  background-color: transparent;
  background-clip: padding-box;

  border-width: 1px;
  border-style: solid;
  border-color: rgba(29, 234, 153, 0.1);
  border-radius: 8px;
  outline: none;

  transition: all 300ms ease-in-out;
}

input:not([disabled])[type='submit']:hover,
input:not([disabled])[type='reset']:hover,
input:not([disabled])[type='button']:hover,
.button:hover,
button:hover {
  background-color: rgba(29, 234, 153, 0.15);
  border-color: rgba(29, 234, 153, 0.15);
}

input:not([disabled])[type='submit']:active,
input:not([disabled])[type='reset']:active,
input:not([disabled])[type='button']:active,
.button:not([disabled]):active,
button:not([disabled]):active {
  background-color: rgba(29, 234, 153, 0.2);
  border-color: 1px solid rgba(29, 234, 153, 0.2);
  transition: background-color 0s;
  transition: border-color 0s;
}

input[type='submit']:disabled,
input[type='reset']:disabled,
input[type='button']:disabled,
.button:disabled,
button:disabled {
  background-color: transparent;
  border-color: rgba(29, 234, 153, 0.1);
  color: rgba(29, 234, 153, 0.1);
  cursor: not-allowed;
}

label {
  color: #ffffff;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0 0.7em 0;
  display: block;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.25em;
  border: none;
  border: solid 1px rgba(255, 255, 255, 0.15);
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}

input[type='text']:invalid,
input[type='password']:invalid,
input[type='email']:invalid,
input[type='tel']:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

/* input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='tel']:focus,
select:focus,
textarea:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
} */

input[type='text'].primary:focus,
input[type='password'].primary:focus,
input[type='email'].primary:focus,
input[type='tel'].primary:focus,
select.primary:focus,
textarea.primary:focus {
  border-color: #3beba8;
  box-shadow: 0 0 0 1px #3beba8;
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.15)' /%3E%3C/svg%3E");
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  height: 2.75em;
  padding-right: 2.75em;
  text-overflow: ellipsis;
}

select option {
  color: #ffffff;
  background: #2e334d;
}

select:focus::-ms-value {
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

input[type='text'],
input[type='password'],
input[type='email'],
select {
  height: 2.75em;
}

textarea {
  padding: 0.75em 1em;
}

body.is-ie textarea {
  min-height: 10em;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.35);
  opacity: 1;
}

:-moz-placeholder {
  color: rgba(255, 255, 255, 0.35);
  opacity: 1;
}

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.35);
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.35);
  opacity: 1;
}

table {
  width: 100%;
  border-collapse: collapse;
  background: rgba(167, 174, 200, 0.1);
  border-radius: 8px;
}

table thead {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

table th {
  font-weight: bold;
  text-align: left;
  padding: 16px;
  font-weight: 700;
  color: #ffffff;
}

table th,
table td {
  vertical-align: middle;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  padding: 16px;
}

table td a,
table td code {
  font-size: 15px;
  line-height: 20px;
}
